import React, {useState} from 'react';
import {ongoingProjects} from "../../content/projects";

const NeuralSDF = () => {
    const project = ongoingProjects.filter(x => x.id === 'neural-sdf')[0];

    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                <span
                                    className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    {project.name}
                                </span>
                            </h1>
                        </header>

                        {project.sections.map((section, j) => {
                            return (
                                <div className={"flex flex-col gap-8 justify-start"}>
                                    <div className="mt-8 sm:mt-10 flex flex-col gap-4">
                                        <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                            <span>
                                                {section.title}
                                            </span>
                                        </h2>
                                        <div className={"grid grid-cols-2 gap-8"}>
                                            <div className="space-y-6 flex flex-col">
                                                {section.content.map((x, i) => {
                                                    return (
                                                        <div>
                                                            {x}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div
                                                className={`flex flex-col gap-6 col-span-1 h-full justify-center ${j % 2 === 1 ? 'order-first' : ''}`}>
                                                <img src={section.video} alt={section.title}
                                                     className={"w-full h-auto"}/>
                                                <div
                                                    className={`grid grid-cols-4 gap-2 items-center justify-center ${j % 2 === 1 ? 'order-first' : ''}`}>
                                                    {section.src.map((src, k) => {
                                                        return (
                                                            <img src={src} alt={section.title}
                                                                 className={"w-full h-auto col-span-1"}/>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default NeuralSDF;
import prem from "../media/volunteering/prem_path.jpg"
import diwali from "../media/volunteering/diwali.png"
import backseat from "../media/volunteering/backseat.png"
import tedx from '../media/volunteering/tedx.jpeg'
import acm from '../media/volunteering/acm.jpeg'
import spark from '../media/volunteering/spark.jpeg'
import kludge from '../media/volunteering/kludge.jpeg'
import azad from '../media/volunteering/azad.jpeg'

export const volunteering = [
    {
        description: "Rescue kitten at the animal shelter \"Prem Path\" (2023)",
        src: prem,
    },
    {
        description: "ACM Student Chapter - Office bearers event (2021)",
        src: acm,
    },
    {
        description: "Project Q - Covid relief campaign as part of a student run non profit - SPARK (2020)",
        src: spark,
    },
    {
        description: "'Women on wheels' campaign as part of Azad Foundation (2019)",
        src: azad,
    },
    {
        description: "TEDx - PESU ECC (2019)",
        src: tedx,
    },
    {
        description: "KLUDGE 6.0 - Hackathon winners (2018)",
        src: kludge,
    },
    {
        description: 'Diwali celebration with "rainbow children" at Loreto (2016)',
        src: diwali,
    },
    {
        description: "'Backseat buddies' ambassadors by the Earth Day Network. A  campaign supporting carpooling to reduce automobile pollution (2013)",
        src: backseat,
    }
]
import studee from '../media/projects/studee.png'
import matlab from '../media/projects/matlab.png'
import c_plus_plus from '../media/projects/c++.png'
import stock from '../media/projects/stock.png'
import job from '../media/projects/job.png'
import aws from '../media/projects/aws.png'
import ar from '../media/projects/ar.png'
import ar_system from '../media/projects/ar-ar-system.png'
import safety_score from '../media/projects/ar-safety-system.png'
import object_detection_framework from '../media/projects/ar-object-detection.png'
import storyboard from '../media/projects/storyboard.png'
import solo_practice from '../media/projects/assistive/solo_practice.jpg'
import group_classes from '../media/projects/assistive/group_classes.jpg'
import ideation1 from '../media/projects/assistive/ideation1.png'
import ideation2 from '../media/projects/assistive/ideation2.png'
import ideation3 from '../media/projects/assistive/ideation3.png'
import ideation4 from '../media/projects/assistive/ideation4.png'
import ideation5 from '../media/projects/assistive/ideation5.png'
import ideation6 from '../media/projects/assistive/ideation6.png'
import ideation7 from '../media/projects/assistive/ideation7.png'
import ideation8 from '../media/projects/assistive/ideation8.png'
import ideation9 from '../media/projects/assistive/ideation9.png'
import ideation10 from '../media/projects/assistive/ideation10.png'
import storyboard1_1 from '../media/projects/assistive/storyboard1_1.png'
import storyboard1_2 from '../media/projects/assistive/storyboard1_2.png'
import storyboard1_3 from '../media/projects/assistive/storyboard1_3.png'
import storyboard1_4 from '../media/projects/assistive/storyboard1_4.png'
import storyboard1_5 from '../media/projects/assistive/storyboard1_5.png'
import storyboard1_6 from '../media/projects/assistive/storyboard1_6.png'
import storyboard2_1 from '../media/projects/assistive/storyboard2_1.png'
import storyboard2_2 from '../media/projects/assistive/storyboard2_2.png'
import storyboard2_3 from '../media/projects/assistive/storyboard2_3.png'
import storyboard2_4 from '../media/projects/assistive/storyboard2_4.png'
import storyboard2_5 from '../media/projects/assistive/storyboard2_5.png'
import storyboard2_6 from '../media/projects/assistive/storyboard2_6.png'
import storyboard3_1 from '../media/projects/assistive/storyboard3_1.png'
import storyboard3_2 from '../media/projects/assistive/storyboard3_2.png'
import storyboard3_3 from '../media/projects/assistive/storyboard3_3.png'
import storyboard3_4 from '../media/projects/assistive/storyboard3_4.png'
import storyboard3_5 from '../media/projects/assistive/storyboard3_5.png'
import storyboard3_6 from '../media/projects/assistive/storyboard3_6.png'
import storyboard4_1 from '../media/projects/assistive/storyboard4_1.png'
import storyboard4_2 from '../media/projects/assistive/storyboard4_2.png'
import storyboard4_3 from '../media/projects/assistive/storyboard4_3.png'
import storyboard4_4 from '../media/projects/assistive/storyboard4_4.png'
import storyboard4_5 from '../media/projects/assistive/storyboard4_5.png'
import storyboard4_6 from '../media/projects/assistive/storyboard4_6.png'
import hardware from '../media/projects/assistive/hardware.jpg'
import interaction1 from '../media/projects/assistive/interaction1.jpg'
import interaction2 from '../media/projects/assistive/interaction2.jpg'
import starry_night from '../media/projects/van_gogh/starry_night.jpg'
import technical_explanation from '../media/projects/van_gogh/technical_explanation.gif'
import sdf from '../media/projects/van_gogh/SDF2.gif'
import clothing_har from '../media/projects/clothing/clothing_har.png'
import clothed from '../media/projects/clothing/clothed.gif'
import unclothed from '../media/projects/clothing/unclothed.gif'
import volumetric_rendering from '../media/projects/volumetric/volumetric_rendering.gif'
import ray_tracing from '../media/projects/volumetric/ray_tracing.gif'
import volumetric from '../media/projects/volumetric/volumetric.jpg'
import cow from '../media/projects/neural_sdf/cow.png'
import rabbit from '../media/projects/neural_sdf/rabbit.png'
import teddy from '../media/projects/neural_sdf/teddy.png'
import seahorse from '../media/projects/neural_sdf/seahorse.png'
import neural_sdf from '../media/projects/neural_sdf/neural_sdf.gif'
import cow_main from '../media/projects/neural_sdf/cow_main.jpg'

import SemiStructuredInterview from "../components/projects/semiStructuredInterview";
import DiaryStudy from "../components/projects/diaryStudy";
import TaskAnalysis from "../components/projects/taskAnalysis";
import {
    Battery,
    Cost,
    GroupClasses1,
    GroupClasses2,
    GroupClasses3,
    GroupClasses4,
    GroupClasses5,
    GroupClasses6,
    Material,
    Miniature,
    Safety,
    SoloPractice1,
    SoloPractice2,
    SoloPractice3,
    SoloPractice4,
    SoloPractice5
} from "../components/icons";

export const projects = [
    {
        name: "Studee",
        description: "Studee is a student portal application that helps students and teachers communicate both virtually and physically by keeping students ENGAGE-ed to use the platform with the help of a Credit-based system.",
        src: studee,
        link: "https://studee.ishitadatta.com"
    },
    {
        name: "Mini C++ Compiler",
        description: "This project for a Mini Compiler for the C++ programming language was made for the course title Compiler Design. The project focuses on generating an intermediate code for the language for specific constructs. It works for constructs such as conditional statements, loops, and the ternary operator. The main functionality of the project is to generate an optimized intermediate code for the given C++ source code.",
        src: c_plus_plus,
        link: "https://github.com/ishitadatta/mini-cpp-compiler"
    },
    {
        name: "S&P Stock Analysis",
        description: "This repository consists of the various models used to train and test the S&P 500 dataset to analyze and predict stocks for the course Data Analytics (UE18CS312).",
        src: stock,
        link: "https://github.com/ishitadatta/S-P-stock-analysis"
    },
    {
        name: "Eyeball Detection with MATLAB",
        description: "Eye detection and tracking has been an active research field in the past years as it adds convenience to a variety of applications. Eye-gaze tracking is been considered as untraditional method of Human Computer Interaction. Eye tracking is considered as the easiest alternative interface methods. For eye tracking and eye detection several different approaches have been proposed and used to implement different algorithms for these technologies. Generally, an eye tracking and detection system can be divided into four steps: Face detection, eye region detection, pupil detection and eye tracking.",
        src: matlab,
        link: "https://github.com/ishitadatta/EyeballDetection"
    },
    {
        name: "Odd Job Finder",
        description: "An odd job finder is a platform independent flutter app that helps you hire people for odd jobs at home as well as apply for the same. There is a vast requirement for housekeeping services. There is no concrete method in acquiring such help other than agencies that are very expensive and do not seem to work very well. This app helps connect an individual to find odd job oppurtunites or even house keeping services located near them. The duration is flexible and the requirements are chosen by the job seeker. This app will help people get the required jobs done at a convenient method. It helps people on the move to get small jobs as well conveniently.",
        src: job,
        link: "https://github.com/ishitadatta/OddJobFinder"
    }
]

export const recentProjects = [
    {
        name: "Augmented reality-guided safety navigation system",
        description: "We are building an AR system that helps residents of Midtown Atlanta navigate from one point in midtown to another point based on factors that are recommended based on street lighting, frequent footfall, pedestrian traffic, time of day and other factors.",
        src: ar,
        id: "ar-guided-safety-navigation-system",
        link: "/projects/ar-guided-safety-navigation-system",
        detailedDescription: [
            "This project is focused on new residents to Midtown Atlanta and helping them navigate the area safely. Midtown can be unsafe for walking especially at night time, and as such new residents may be hesitant to explore the city when they are not familiar with the environment." +
            "Key design requirements include customization and prioritization of safety factors, discreet and non-visual feedback, connecting with friends, family, and local authorities, support for community engagement, and combining safety and navigation features into a single solution."
        ],
        researchMethods: [
            "We did a lot of indirect and direct research methods. Our direct research methods involved semi-structured interviews and surveys, responses from which built our user needs findings and affinity model."
        ],
        researchFindings: [
            {
                researchFindings: "Users reported various factors that contributed to their perception of safety: presence of houseless individuals, pedestrian traffic, lighting, security and police presence, open businesses, and sidewalk conditions.",
                userNeeds: "User needs to be able to choose what factors are the most important considerations when deciding on the safest route.",
                designImplications: "Customization and personalization when it comes to identifying safety factors is needed, as male and female users will prioritize different factors in their assessment of safety.",
                source: "Indirect research, interview, survey"
            },
            {
                researchFindings: "Users found it safer when traveling with someone rather than walking alone especially at night and especially if female, because in a group there is less chance to be harassed and there are more people to look out for threats.",
                userNeeds: "There is high desirability for communal aspects in the determination of safety",
                designImplications: "Users should have access to a walking companion in instances where they feel unsafe by themselves.",
                source: "Interview, Indirect research"
            },
            {
                researchFindings: "Users do not feel very connected to the broader community in Midtown, as it is part of a big city where it is difficult to make person-to-person connections, and the opportunities for community engagement are not as widely promoted outside of existing social circles. ",
                userNeeds: "Users need the resources and opportunities to get connected with the community and meet new people.",
                designImplications: "There needs to be a focus on increasing awareness and support of community engagement.",
                source: "Interview"
            },
            {
                researchFindings: "Users often feel more unsafe or worried when they receive certain emergency alerts regarding crimes and hazards in the Midtown area.",
                userNeeds: "Users should receive relevant safety information without being overwhelmed or scared.",
                designImplications: "Urgent data, statistics, and information should be presented in a way that is appropriate, relevant to and considerate of the user.",
                source: "Interview"
            },
            {
                researchFindings: "Users do not want to be looking at their devices while navigating, as it makes them look lost, uncertain, or distracted which can make them more likely to be targeted.",
                userNeeds: "Users need to be able to navigate without being or appearing to be vulnerable. ",
                designImplications: "There should be a discreet and/or non-visual solution to convey relevant information without requiring excess attention. This is particularly important for female users, because they report feeling more susceptible to harassment.",
                source: "Interview"
            },
            {
                researchFindings: "Users feel safer about friends being aware about their location when navigating on the street, because their friends can be called on for support.",
                userNeeds: "Users need to have those in their trusted circle aware of their whereabouts in order to feel more safe when they are alone.",
                designImplications: "There should be a solution that automatically connects the user to their friends, family, and/or community and updates them appropriately.",
                source: "Interview"
            },
            {
                researchFindings: "One combined safety and navigation solution is preferred over multiple disparate products, as users do not like downloading or depending on a lot of apps and wish for deeper integrations between apps.",
                userNeeds: "Users wish to have one application over having multiple apps for different functions/features.",
                designImplications: "There should be a single multi-purpose solution that integrates both safety and navigation features in a seamless way.",
                source: "Interview"
            },
        ],
        productFeatures: [
            {
                name: "AR System",
                description: [
                    "Provides users with real-time augmented safety information through highlighting safer pathways, factoring in pedestrian density, lighting, and other relevant data.",
                    "Gives clear, immediate visual cues, ensuring users choose safer routes while staying aware of their surroundings.",
                    "Design Requirements Met: The AR system serves as a single multi-purpose solution and allows users to customize their route preferences based on prioritized safety factors."
                ],
                img: ar_system
            },
            {
                name: "Safety Score",
                description: [
                    "Analyzes a variety of factors to calculate a personalized safety score for each route by accommodating what the user’s “notion of safety” is.",
                    "The user receives a ranked recommendation of pathways, allowing them to choose the safest and most efficient route. Our direct research methods gave us the list of factors that would feed into the input parameters of a recommendation system to calculate the scores and output safety scores based on the user’s preference and street circumstance.",
                    "Design Requirements Met: The scoring system uses Customization and Prioritization of Safety Factors to generate a personalized safety score for each route that aligns with the user's unique safety priorities."
                ],
                img: safety_score
            },
            {
                name: "Object Detection Framework",
                description: [
                    "Helps identify key elements in the user's environment, such as pedestrians, sidewalks, crosswalks, street lighting, and hazards like broken pavements.",
                    "Scans the surroundings for important features that contribute to a safer journey. Machine learning frameworks such as YOLO is to be used to detect objects on the road.",
                    "Design Requirements Met: Customization and Prioritization of Safety Factors, as the user can choose to highlight / emphasize the factors that are most important to them to be detected"
                ],
                img: object_detection_framework
            }
        ],
        storyboard: {
            text: "An augmented reality system that helps users navigate safely through a city while integrating object detection, community alerts, and real-time updates.",
            img: storyboard
        }
    },
    {
        name: "Onboarding SWEs using Gen AI - Industry collaboration with AWS",
        description: "As part of this project we are conducting extensive user research to understand gaps in existing onboarding software.",
        src: aws,
        id: "onboarding-swes-using-gen-ai",
        link: "/projects/onboarding-swes-using-gen-ai",
        problemStatement: [
            "This project was an industry sponsored project with AWS as our industry sponsor. Our Problem Statement was about how we can use generative AI to facilitate the onboarding process for new grad and intern software development engineers?"
        ],
        significance: [
            {
                title: "Benefits",
                content: [
                    "Streamlining the onboarding process for new graduates and interns",
                    "Helping them quickly adapt to the work environment",
                    "Reduced stress",
                    "Improved efficiency"
                ]
            },
            {
                title: "Innovations",
                content: [
                    "Leveraging generative AI to automate access to relevant information",
                    "Personalized learning pathways",
                    "Enhancing the onboarding experience",
                    "Reduced manual tasks"
                ]
            },
            {
                title: "Impact",
                content: [
                    "Providing personalized learning experiences",
                    "Enabling new hires to focus on core responsibilities",
                    "Improved retention, satisfaction, and productivity in the early stages of employment",
                ]
            }
        ],
        process: [
            {
                title: "Target User/Subject Groups",
                content: [
                    "New graduate and intern software engineers",
                    "Early career individuals transitioning from academic learning to practical software development roles"
                ]
            },
            {
                title: "Behavior of Interest",
                content: [
                    "Onboarding efficiency: how quickly and effectively new hires can become productive",
                    "Learning and adapting to company-specific workflows, tools, and coding standards",
                    "Engagement with AI-driven support system during the onboarding phase"
                ]
            },
            {
                title: "Stakeholders",
                content: [
                    "Primary Stakeholders: Software engineers, software development managers, HR, onboarding teams",
                    "Secondary Stakeholders: Project teams, senior engineers (mentors), learning & development departments"
                ]
            },
            {
                title: "Context of Use",
                content: [
                    "Onboarding occurs in hybrid or remote environments with varying levels of direct human mentorship",
                    "Integration with Development Environments (IDEs), version control systems, and other technical tools",
                    "Real-time AI assistance providing learning resources, task guidance, and coding support"
                ]
            },
            {
                title: "Business/Market Solution",
                content: [
                    "Tech industry faces high comptetion for talent; efficient onboarding can reduce time-to-productivity and increase retention",
                    "Need for scalable solutions to onboard large cohorts of new grads efficiently",
                    "Generative AI provides opportunities for personalized, real-time onboarding support that traditional methods may not scale well to deliver"
                ]
            }
        ],
        researchMethods: [
            {
                name: "Structured Interview",
            },
            {
                name: "Semi-structured Interview",
                component: <SemiStructuredInterview/>
            },
            {
                name: "Diary Study",
                component: <DiaryStudy/>
            },
            {
                name: "SWOT Analysis",
            },
            {
                name: "Task Analysis",
                component: <TaskAnalysis/>
            },
            {
                name: "Website Walkthrough",
            },
            {
                name: "Competitive Analysis",
            },
            {
                name: "Heuristic Evaluation - Amazon Q",
            },
            {
                name: "Case Study - Agentic AI",
            },
            {
                name: "Stakeholder Survey",
            }
        ],
        semiStructuredInterview: {
            interview: [
                {
                    title: "Ease of Use",
                    question: "How easy was it to navigate the GenAI tools?",
                    rationale: "To asses the user-friendliness of the AI."
                },
                {
                    title: "Access to Resources",
                    question: "Were the AI-suggested onboarding resources helpful?",
                    rationale: "To evaluate how well AI supports timely access to resources."
                },
                {
                    title: "AI Support",
                    question: "Did the AI effectively guide you through troubleshooting?",
                    rationale: "To understand how AI assists in problem-solving."
                },
                {
                    title: "Satisfaction",
                    question: "How satisfied are you with the AI-driven onboarding process?",
                    rationale: "To gauge overall satisfaction and improvement areas."
                }
            ]
        },
        designImplications: [
            {
                finding: "Many SWEs felt that the onboarding was too structured and did not cater to their existing skill set, experience, and learning style.",
                userNeed: "A user requires more personalization in their onboarding process.",
                designImplication: "Customizable platform for personalized learning based on inputs and information from the user."
            },
            {
                finding: "Access and permissions to resources and tools during onboarding is a complicated process and can cause delays and/or blockers.",
                userNeed: "Users do not want to feel burdened or restricted by access and permission issues or involve more people / teams than necessary.",
                designImplication: "Dynamically automate personalized access pathways for new users so that they will not be blocked or delayed."
            },
            {
                finding: "SWEs need to deal with some trivial and repetitive yet unavoidable tasks at work.",
                userNeed: "Users want to avoid doing menial / repetitive tasks so that they can focus their time and effort on more serious and time-consuming tasks.",
                designImplication: "A system that can automatically track work progress and complete tasks that have low actual output but take a long time."
            },
            {
                finding: "SWEs are concerned that GenAI implementation could result in performance monitoring or scoring.",
                userNeed: "Users want to feel comfortable / not intimidated or threatened or controlled by the presence of generative AI tools.",
                designImplication: "Implement generative AI tools in a responsible way so as to avoid micromanagement or harm toward employees."
            },
            {
                finding: "Documentation is a key factor in the onboarding and troubleshooting process, but it is often outdated, irrelevant, or scattered across multiple platforms, making it difficult for SWEs to find what they need.",
                userNeed: "SWEs need a centralized and easily accessible documentation to reduce confusion and frustration.",
                designImplication: "A unified, searchable documentation hub to help new users quickly find relevant information and streamline their onboarding process."
            },
            {
                finding: "New SWEs often need to reach out to other team members or external groups for assistance in onboarding and technical issues.",
                userNeed: "SWEs need a more efficient method to schedule meetings with necessary employees and management, as well as account for situations where the people they need help from may be occupied.",
                designImplication: "Intelligent scheduling system that facilitates communication between teams and also offers chatbot functionality when needed."
            },
            {
                finding: "New SWEs face onboarding challenges with unfamiliar internal tools and technologies.",
                userNeed: "SWEs need a way to quickly and effectively onboard to their specific team by learning all the required tools and technologies as soon as possible.",
                designImplication: "Create personalized step-by-step learning plans by analyzing a user's current knowledge, goals, and progress."
            },
            {
                finding: "SWEs value hands-on learning and work-relevant topics in their onboarding programs.",
                userNeed: "SWEs need more opportunities to gain hands-on exposure to certain tasks.",
                designImplication: "AI-generated problems to practice fixing bugs and completeing tasks, or AI guidance to help ease new SWEs into starting easier tasks."
            }
        ],
        journeyMap: {
            categories: [
                {
                    title:"Compliance",
                    count: 2
                },
                {
                    title: "Collaboration",
                    count: 2
                },
                {
                    title: "Training and Learning",
                    count: 1
                },
                {
                    title: "Gathering Information",
                    count: 3
                },
                {
                    title: "Task Efficiency",
                    count: 1
                }
            ],
            table: [
                {
                    content: [
                        {
                            text: "User Activities",
                            span: 1
                        },
                        {
                            text: "Access Control",
                            span: 1
                        },
                        {
                            text: "Permissions",
                            span: 1
                        },
                        {
                            text: "Buddy System",
                            span: 1
                        },
                        {
                            text: "Team",
                            span: 1
                        },
                        {
                            text: "Code Debugging",
                            span: 1
                        },
                        {
                            text: "Documentation",
                            span: 1
                        },
                        {
                            text: "Code Navigation",
                            span: 1
                        },
                        {
                            text: "Inquiry",
                            span: 1
                        },
                        {
                            text: "Code Deployment",
                            span: 1
                        }
                    ]
                },
                {
                    content: [
                        {
                            text:"SWE Goals",
                            span: 1
                        },
                        {
                            text: "Achieve heirarchical level of accesses",
                            span: 1
                        },
                        {
                            text: "Get permissions from different people",
                            span: 1
                        },
                        {
                            text: "Use the buddy system to adjust the development environment",
                            span: 1
                        },
                        {
                            text: "Find the right team members for guidance and teamwork",
                            span: 1
                        },
                        {
                            text: "Efficiently debug code with AI support",
                            span: 1
                        },
                        {
                            text: "Learn the company's coding standards and procedures",
                            span: 1
                        },
                        {
                            text: "Seamlessly navigate the codebase",
                            span: 1
                        },
                        {
                            text: "Ask the right questions to get help quickly",
                            span: 1
                        },
                        {
                            text: "Deploy the code with minimal errors",
                            span: 1
                        }
                    ]
                },
                {
                    content: [
                        {
                            text:"Touchpoints",
                            span: 1
                        },
                        {
                            text: "Interaction with IT systems for access requests",
                            span: 1
                        },
                        {
                            text: "Automated access approval workflows",
                            span: 1
                        },
                        {
                            text: "Buddy system communiations (Slack, Teams)",
                            span: 1
                        },
                        {
                            text: "Team meetings and standups",
                            span: 1
                        },
                        {
                            text: "Code debugging platforms (IDEs with AI suggestions)",
                            span: 1
                        },
                        {
                            text: "AI-assisted learning tools, internal wikis",
                            span: 1
                        },
                        {
                            text: "Code documentation platforms",
                            span: 1
                        },
                        {
                            text: "Query systems or senior engineers for support",
                            span: 1
                        },
                        {
                            text: "Deployment platforms (e.g. Jenkins, GitHub Actions)",
                            span: 1
                        }
                    ]
                },
                {
                    content: [
                        {
                            text:"Experience",
                            span: 1
                        },
                        {
                            text: "Frustration with complex access requests",
                            span: 1
                        },
                        {
                            text: "Long turnaround times for permissions",
                            span: 1
                        },
                        {
                            text: "Positive buddy support but occasional slow response times",
                            span: 1
                        },
                        {
                            text: "Smooth interactions but confusion in finding the right person",
                            span: 1
                        },
                        {
                            text: "Effective learning but time-consuming manual code review",
                            span: 1
                        },
                        {
                            text: "Helpful AI suggestions for debugging but gaps in documentation",
                            span: 1
                        },
                        {
                            text: "Difficulty navigating large codebases",
                            span: 1
                        },
                        {
                            text: "Slow response times for specific inquiries",
                            span: 1
                        },
                        {
                            text: "Quick deployment for simple tasks. Errors and delays during code deployment for complex tasks",
                            span: 1
                        }
                    ]
                },
                {
                    content: [
                        {
                            text:"Business Goal",
                            span: 1
                        },
                        {
                            text: "Ensure security and proper access management",
                            span: 2
                        },
                        {
                            text: "Foster teamwork and quick onboarding",
                            span: 2
                        },
                        {
                            text: "Minimize debugging time with AI",
                            span: 2
                        },
                        {
                            text: "Improve knowledge transfer efficiency",
                            span: 2
                        },
                        {
                            text: "Faster and error-free code deployment",
                            span: 1
                        },
                    ]
                },
                {
                    content: [
                        {
                            text:"KPIs",
                            span: 1
                        },
                        {
                            text: "Access granted within 24hrs",
                            span: 1
                        },
                        {
                            text: "Compliance with security standards",
                            span: 1
                        },
                        {
                            text: "Average time to onboard via the buddy system",
                            span: 2
                        },
                        {
                            text: "Time to complete AI-supported debugging tasks",
                            span: 1
                        },
                        {
                            text: "Satisfaction scores for training content",
                            span: 1
                        },
                        {
                            text: "Average time to retrieve documentation",
                            span: 1
                        },
                        {
                            text: "First-time inquiry resolution rate",
                            span: 1
                        },
                        {
                            text: "Time from code completion to deployment",
                            span: 1
                        }
                    ]
                },
                {
                    content: [
                        {
                            text:"Organizational Activities",
                            span: 1
                        },
                        {
                            text: "IT team handles access requests",
                            span: 1
                        },
                        {
                            text: "Automated system reviews permissions",
                            span: 1
                        },
                        {
                            text: "Assign mentors or buddies for each new hire",
                            span: 2
                        },
                        {
                            text: "Provide AI tools for debugging and code review",
                            span: 1
                        },
                        {
                            text: "Internal documentation available",
                            span: 1
                        },
                        {
                            text: "Ensure complete and updated code documentation",
                            span: 1
                        },
                        {
                            text: "N/A",
                            span: 1
                        },
                        {
                            text: "Streamline deployment pipelines and automate checks",
                            span: 1
                        }
                    ]
                },
                {
                    content: [
                        {
                            text:"Responsible",
                            span: 1
                        },
                        {
                            text: "IT and security teams",
                            span: 2
                        },
                        {
                            text: "Buddy system coordinators, team leads",
                            span: 1
                        },
                        {
                            text: "Manager",
                            span: 1
                        },
                        {
                            text: "Technical training teams",
                            span: 2
                        },
                        {
                            text: "Documentation team, senior engineers",
                            span: 2
                        },
                        {
                            text: "DevOps team, AI tool providers",
                            span: 1
                        }
                    ]
                },
                {
                    content: [
                        {
                            text:"Technology Systems",
                            span: 1
                        },
                        {
                            text: "Access control systems",
                            span: 1
                        },
                        {
                            text: "IT ticketing software",
                            span: 1
                        },
                        {
                            text: "Slack, Microsoft Teams",
                            span: 2
                        },
                        {
                            text: "GitHub, AI Learning Platforms, IDEs",
                            span: 2
                        },
                        {
                            text: "Documentation platforms, Code-navigation tools",
                            span: 2
                        },
                        {
                            text: "Jenkins, GitHub Actions, Cloud deployment systems",
                            span: 1
                        }
                    ]
                },
            ]
        }
    },
    {
        name: "Assistive Yoga Clothing",
        description: "",
        src: ideation5,
        id: "assistive-yoga-clothing",
        link: "/projects/assistive-yoga-clothing",
        problemStatement: [
            "Many yoga practitioners, especially beginners, struggle to maintain proper breath alignment with poses, limiting the mental and physical benefits of their practice. Traditional methods lack real-time guidance for breath control, which is essential for effective mindfulness and stress reduction."
        ],
        observationAndLandscapeAnalysis: [
            {
                name: "Solo Practice",
                src: solo_practice,
                content: [
                    {
                        text: "Solo yoga allows practitioners to tune in more deeply into their physical and mental states without external distractions,",
                        icon: <SoloPractice1 />
                    },
                    {
                        text: "Practitioners can control the flow and intensity of their practice, enabling individualized progression and focus on personal goals.",
                        icon: <SoloPractice2 />
                    },
                    {
                        text: "Solo practice provides a safe space for emotional release and self-reflection, enhancing emotional well-being.",
                        icon: <SoloPractice3 />
                    },
                    {
                        text: "With no external cues, individuals rely more on thier own internal sensations to guide movement, strengthening the mind-body connection.",
                        icon: <SoloPractice4 />
                    },
                    {
                        text: "Lack of external accountability in solo practice can make maintaining regularity and discipline challenging for some.",
                        icon: <SoloPractice5 />
                    }
                ]
            },
            {
                name: "Group Classes",
                src: group_classes,
                content: [
                    {
                        text: "Group classes foster a sense of community which can enhance motivation and accountability.",
                        icon: <GroupClasses1 />
                    },
                    {
                        text: "Instructors provide real-time feedback and adjustments, helping participants improve their form and alignment.",
                        icon: <GroupClasses2 />
                    },
                    {
                        text: "Practicing with others can create a collective energy that enhances the experience and encourages individuals to push their boundaries.",
                        icon: <GroupClasses3 />
                    },
                    {
                        text: "Participants can learn new techniques by observing others in the class, which can be especially helpful for beginners.",
                        icon: <GroupClasses4 />
                    },
                    {
                        text: "Group settings may lead to self-comparison, which can either motivate or hinder progress depending on the individual's mindset.",
                        icon: <GroupClasses5 />
                    },
                    {
                        text: "The external structure of group classes often keeps participants more consistent and disciplined in their practice.",
                        icon: <GroupClasses6 />
                    }
                ]
            }
        ],
        nonDigital: [
            {
                name: "Yoga mats",
                category: "Products",
                images: [],
                content: [
                    "An early variety made of rubber carpet underlay pioneered by the yoga teacher Angela Farmer in 1982.",
                    "Yoga mats are designed to lightly cushion low impact activity and provide no-slip grip.",
                    "4mm is the best yoga mat thickness.",
                    "Today, there are also smart yoga mats to assist with yoga postures and provide biofeedback to the user."
                ]
            },
            {
                name: "Yoga straps",
                category: "Products",
                images: [],
                content: [
                    "Straps are an extremely versatile yoga accessory with many possible uses.",
                    "The main benefit is to aid yogis with their stretching, increase flexibility and decrease tension."
                ]
            },
            {
                name: "Yoga retreats",
                category: "Services",
                images: [],
                content: [
                    "Yoga retreats offer a break from everyday life, allowing participants to focus on their practice while also benefitting from the peace and tranquility of natural surroundings.",
                    "Retreats often include not just yoga sessions but also meditation, wellness workshops, and healthy meals, providing a well-rounded experience for mind, body, and spirit.",
                    "Yoga retreats help deepen personal practice through dedicated time, space, and instruction, allowing for more progress in flexibility, strength, and mindfullness.",
                    "The serene environment encourages mental clarity and stress reduction, promoting overall well-being."
                ]
            },
            {
                name: "Outdoor spaces",
                category: "Environments",
                images: [],
                content: [
                    "Practicing yoga outdoors provides a natural environment that enhances mindfulness and connection with nature",
                    "Fresh air and natural light during outdoor sessions can help improve mood and energy levels, making the practice more refreshing and rejuvenating.",
                    "Outdoor yoga classes in public parks make yoga more accessible to a broader audience, reducing barriers like cost and location.",
                    "Practitioners need to adjust to changing weather conditions and uneven surfaces.",
                    "The lack of controlled environments (like in studios) may require modifications, such as using props or mats designed for rough terrain."
                ]
            },
            {
                name: "Yoga apps",
                category: "Products",
                images: [],
                content: [
                    "Yoga apps provide instant access to a wide variety of classes, from beginner to advanced levels, at the user's convenience.",
                    "These apps can be used anytime, anywhere, making yoga accessible to people with busy schedules or those who cannot attend in-person classes.",
                    "Many yoga apps, like Glo and Yoga Studio, offer customizable features, allowing users to tailor their practice according to their preferences (e.g. session length, focus areas like flexibility or relaxation).",
                    "Apps like Daily Yoga track user progress, providing feedback on improvement and offering personalized class recommendations based on performance metrics."
                ]
            },
            {
                name: "YouTube channels offering yoga tutorials and live classes",
                category: "Products",
                images: [],
                content: [
                    "YouTube offers a wide range of yoga tutorials and live classes, making yoga accessible to a global audience for free.",
                    "Users can practice from the comfort of their homes without the need for costly subscriptions or memberships.",
                    "Populatr channels like Yoga with Adriene, Fightmaster Yoga, and Boho Beautiful provide high-quality yoga content, offering everything from beginner classes to advanced flows."
                ]
            },
            {
                name: "Virtual reality yoga experiences",
                category: "Services",
                images: [],
                content: [
                    "Applications like TRIPP, Supernatural, and Flow VR are examples of virtual reality platforms that incorporate yoga and mindfulness practices.",
                    "These platforms offer guided sessions with real-time adjustments and a focus on physical and mental well-being."
                ]
            },
            {
                name: "Ambient light settings during yoga",
                category: "Environments",
                images: [],
                content: [
                    "Gentle, dim lighting in yoga studios or practice spaces helps create a calming and pracefull environment, essential for deepening mindfulness and concetration.",
                    "Certain yoga environments use chromotherapy (color therapy), where different light colors are used to influence mood and energy."
                ]
            }
        ],
        designRecommendations: [
            {
                name: "Environment-Responsive Yoga Apps",
                description: "Apps that adjust recommended yoga routines based on the user's location and environment (outdoor, studio, or home).",
                src: null,
                content: [
                    "Users practice yoga in various environments: indoors at home, in studios or outdoors. Each environment comes with unique challenges (e.g. space, distractions, noise) and opportunities (e.g. fresh air outdoors).",
                    "An environment-responsive yoga app adjusts routines based on the user's location, providing the best practice experience suited to their current surroundings.",
                    "Observing that users often face environmental limitations (like small spaces or noisy areas) highlights the need for adaptability in yoga routines. At home, users may need shorter, more flexible routines due to space constraints or interruptions, while outdoor users may benefit from breathing exercises that take advantage of fresh air."
                ]
            },
            {
                name: "Customizable Practice Plans",
                description: "Digital platforms should offer fully customizable yoga sequences based on individual fitness levels, time constraints, and goals.",
                src: null,
                content: []
            }
        ],
        ideation: [
            {
                name: "Mood-Responsive Sounds",
                src: ideation1,
                content: [
                    "The app for the sounds adjusts soundscapes dynamically based on the user's movements during yoga practice.",
                    "As the user transitions through different poses, the sound shifts between environments such as ocean waves, forest sounds, and gentle wind.",
                    "This creates a personalized, calming atmosphere that enhances mindfulness and mood during the session."
                ]
            },
            {
                name: "Adaptive Sizing Yoga Mat",
                src: ideation2,
                content: [
                    "The mat detects the user’s size and posture using pressure sensors, adjusting its dimensions automatically.",
                    "Conductive threads expand or contract based on movement, offering personalized support during poses.",
                    "The mat adapts in real-time without buttons or speech, enhancing the yoga experience intuitively."
                ]
            },
            {
                name: "Breath-Paced Floor Projection",
                src: ideation3,
                content: [
                    "The projected pattern adjusts to the user's breathing rhythm, expanding and contracting to guide calm inhalation and exhalation.",
                    "The projection responds in real-time to the user’s breath, creating a relaxing and intuitive breathing flow.",
                    "The system supports mindfulness through non-intrusive visual feedback, enhancing focus without using buttons or verbal commands."
                ]
            },
            {
                name: "Gesture-Controlled Interactive Mirror",
                src: ideation4,
                content: [
                    "The AR mirror provides visual cues for correcting yoga poses, guiding the user toward proper alignment using body tracking.",
                    "Encourages users to stay focused on their practice without distractions, using gesture-based adjustments instead of verbal cues.",
                    "The mirror adapts to the user’s movements, showing posture corrections in real time, enhancing the user's form and flow through non-verbal interaction."
                ]
            },
            {
                name: "Guided Vibrational Yoga Clothing",
                src: ideation5,
                content: [
                    "The clothing delivers vibrations to guide inhale and exhale timing, removing the need for visual prompts.",
                    "Helps maintain mindfulness during breathing exercises, keeping the user focused on the present moment.",
                    "Supports seamless breathing rhythms without relying on screens or external displays."
                ]
            },
            {
                name: "Energy Flow Projection",
                src: ideation6,
                content: [
                    "Energy flows are projected around the user during poses, creating a visual representation of intensity.",
                    "The energy projections enhance body awareness, allowing the user to stay mindful of their movements.",
                    "The projection reacts to pose transitions, offering real-time feedback that corresponds to the strength and balance in each pose."
                ]
            },
            {
                name: "Clothing-Responsive AR Visuals",
                src: ideation7,
                content: [
                    "The user wears yoga clothing with reflective markers that interact with an AR system, projecting visual effects during yoga sessions.",
                    "As the user moves, light trails or energy patterns are projected around the body, visually enhancing the connection between movement and surroundings.",
                    "The AR visuals adapt to the intensity of each pose, creating a harmonious and immersive practice environment."
                ]
            },
            {
                name: "Temperature-Responsive Breathing Support",
                src: ideation8,
                content: [
                    "As the user inhales, the object warms slightly, and as they exhale, it cools down, guiding the breath cycle through temperature changes.",
                    "The system offers temperature-based cues to help users focus on and regulate their breathing rhythm.",
                    "Integrated sensors monitor breathing patterns and adjust the temperature to align with the surrounding environment."
                ]
            },
            {
                name: "Ambient Lights",
                src: ideation9,
                content: [
                    "Ambient lighting adjusts based on the chosen yoga practice (e.g., restorative, vinyasa, or meditation), creating a customized atmosphere for each session.",
                    "The lights shift in intensity and color to support focus, relaxation, or energy flow during different yoga routines.",
                    "By aligning with the user’s breathing patterns or specific yoga postures, the lights help create a calming, immersive environment."
                ]
            },
            {
                name: "Posture-Sensitive Yoga Blocks",
                src: ideation10,
                content: [
                    "Yoga blocks equipped with smart sensors detect the user’s pose and dynamically adjust their height and firmness to provide optimal support for each movement.",
                    "Subtle vibrations guide the user, signaling when the block has reached the correct height or when adjustments are needed for better alignment.",
                    "The adaptive blocks cater to individual flexibility and strength, helping users maintain balance and alignment in challenging poses without manual adjustments."
                ]
            }
        ],
        storyboard: [
            {
                name: "AR Mirror Pose Alignment",
                content: [
                    {
                        src: storyboard1_1,
                        name: "Initial Pose Setup",
                        text: "The user stands in front of the AR mirror in a basic yoga pose. The mirror is ready to assess the posture."
                    },
                    {
                        src: storyboard1_2,
                        name: "Detecting Misalignments",
                        text: "The AR mirror detects misalignments, highlighting areas like the arms and legs with red/yellow markers."
                    },
                    {
                        src: storyboard1_3,
                        name: "Providing Feedback",
                        text: "The AR mirror shows arrows and text, guiding the user on how to adjust their arms and legs for correct alignment."
                    },
                    {
                        src: storyboard1_4,
                        name: "User Adjusts Pose",
                        text: "The user follows the mirror's feedback, making adjustments in real-time, while the AR system continues to track the changes."
                    },
                    {
                        src: storyboard1_5,
                        name: "Final Alignment Check",
                        text: "The AR mirror shows positive feedback (e.g., green checkmark), confirming the user has corrected the pose."
                    },
                    {
                        src: storyboard1_6,
                        name: "User in Perfect Alignment",
                        text: "The user holds the corrected pose with the AR mirror showing no misalignments, providing celebratory feedback."
                    },
                ]
            },
            {
                name: "Adaptive Sizing Yoga Mat",
                content: [
                    {
                        src: storyboard2_1,
                        name: "Initial Setup",
                        text: "The toddler begins by unrolling the adaptive yoga mat. The mat appears at its default, standard size."
                    },
                    {
                        src: storyboard2_2,
                        name: "Size Measurement",
                        text: "As the toddler steps onto the mat, sensors are activated, and the mat starts measuring the child's height and body proportions."
                    },
                    {
                        src: storyboard2_3,
                        name: "Automatic Adjustment",
                        text: "The mat begins to expand or contract based on the toddler's measurements, dynamically adjusting to the right size."
                    },
                    {
                        src: storyboard2_4,
                        name: "Full Adjustment",
                        text: "The mat has successfully expanded to accommodate both the length and width required for the child."
                    },
                    {
                        src: storyboard2_5,
                        name: "Engaging in Activity",
                        text: "The toddler is now actively engaging with the yoga mat, crawling on it, with the mat supporting the movement."
                    },
                    {
                        src: storyboard2_6,
                        name: "Post-Practice Reset",
                        text: "After the session, the mat begins to contract back to its original size as the toddler steps off."
                    },
                ]
            },
            {
                name: "Clothing-Responsive AR Visuals",
                content: [
                    {
                        src: storyboard3_1,
                        name: "Initial Setup",
                        text: "The user sits on the mat in reflective-marked yoga clothing, ready for the session."
                    },
                    {
                        src: storyboard3_2,
                        name: "Activation of AR Visuals",
                        text: "The user stands in Mountain Pose as soft, glowing lights begin to project from their clothing."
                    },
                    {
                        src: storyboard3_3,
                        name: "Dynamic Pose Visualization",
                        text: "In Downward Dog, the AR system projects flowing light from the user's arms and legs, following the movement."
                    },
                    {
                        src: storyboard3_4,
                        name: "Warrior Pose with Full AR Engagement",
                        text: "The user holds Warrior Pose as a halo of light surrounds the body, emphasizing strength and balance."
                    },
                    {
                        src: storyboard3_5,
                        name: "Advanced Pose with Interactive Elements",
                        text: "The user performs Tree Pose, with energy patterns swirling around their arms and legs."
                    },
                    {
                        src: storyboard3_6,
                        name: "Resting Pose with Fading AR Effects",
                        text: "The user relaxes in Child's Pose, as the AR effects gently fade."
                    },
                ]
            },
            {
                name: "Vibrational Yoga Clothing",
                content: [
                    {
                        src: storyboard4_1,
                        name: "Initial Setup",
                        text: "The user turns on the breathing guidance mode. The system calibrates, with a gentle vibration as a readiness signal."
                    },
                    {
                        src: storyboard4_2,
                        name: "Inhalation Trigger",
                        text: "The vibrational motor gently vibrates, guiding the user to inhale for a duration of 4 seconds."
                    },
                    {
                        src: storyboard4_3,
                        name: "Pause for Breath Hold",
                        text: "The vibration stops briefly, indicating to hold the breath."
                    },
                    {
                        src: storyboard4_4,
                        name: "Exhalation Trigger",
                        text: "As the user exhales, the light shifts to a calming red, dimming as the exhale continues."
                    },
                    {
                        src: storyboard4_5,
                        name: "Pause for Cycle End",
                        text: "The vibration stops again, signaling to hold the breath for 4 seconds after exhaling."
                    },
                    {
                        src: storyboard4_6,
                        name: "Rhythmic Sensory Cues",
                        text: "The guided breathing continues, helping the user stay focused and calm through a rhythmic pattern of sensory cues."
                    },
                ]
            }
        ],
        designDirection: [
            {
                name: "Basic Breath Cycle",
                content: [
                    "Inhale: 4-6 seconds",
                    "Exhale: 4-6 seconds",
                    "This is a common breathing pattern in gentle yoga practices, where the focus is on a steady and balanced breath."
                ]
            },
            {
                name: "Ujjayi Breath (Ocean Breath)",
                content: [
                    "Inhale: 4-6 seconds",
                    "Exhale: 4-6 seconds",
                    "Ujjayi breath is used in many yoga practices, including Vinyasa and Ashtanga, and is characterized by a gentle sound at the back of the throat. It aims for a smooth, equal breath cycle."
                ]
            },
            {
                name: "Relaxation Breathing",
                content: [
                    "Inhale: 4 seconds",
                    "Hold: 7 seconds (optional)",
                    "Exhale: 8 seconds",
                    "This pattern is often used to promote relaxation and reduce stress by emphasizing a longer exhale."
                ]
            },
            {
                name: "Pranayama Breathing",
                content: [
                    "Nadi Shodhana (Alternate Nostril Breathing): Inhale and exhale can be 4-6 seconds each, often balanced.",
                    "Kumbhaka (Breath Retention): In advanced pranayama, breath retention can be introduced with cycles like inhale 4 seconds, hold 4 seconds, exhale 4 seconds, and then gradually increase the timing for deeper practices."
                ]
            },
            {
                name: "Box Breathing",
                content: [
                    "Inhale: 4 seconds",
                    "Hold: 4 seconds",
                    "Exhale: 4 seconds",
                    "Hold: 4 seconds",
                    "This method is often used for focus and relaxation, with balanced intervals for inhale, hold, exhale, and hold again."
                ]
            }
        ],
        designJustification: [
            {
                feature: "Vibrational cloth",
                justification: "Provides tactile feedback for breath guidance, enhancing mindfulness and helping users stay on track with their breathing rhythm without relying on visual or auditory cues."
            },
            {
                feature: "Lighting cues",
                justification: "Offers visual feedback for breath phases (inhale/exhale) that can be helpful in dim environments or for individuals who respond better to visual stimuli. Creates a calming atmosphere conducive to relaxation and focus."
            },
            {
                feature: "Configurable breathing modes",
                justification: "Supports flexibility for various breathing practices, such as box breathing or 4-7-8 techniques, accommodating personalized practices and adjusting to users' preferences."
            },
            {
                feature: "Hands-free interaction",
                justification: "Ensures uninterrupted flow during yoga practice, allowing users to focus on their breathing and posture without needing to manipulate controls, which enhances user experience and accessibility."
            },
            {
                feature: "Embedded Technology Integration",
                justification: "Embedding technology directly into clothing allows for an unobtrusive design, enabling a seamless experience where users feel supported by the feedback system without the bulkiness of external devices."
            }
        ],
        keyFeatures: [
            {
                name: "Vibrational Breath Cues",
                content: [
                    "Provides timed vibrational feedback directly through the clothing to guide inhale, exhale, and hold phases, ensuring synchronized breathing without visual dependency.",
                    "Adjustable vibration intensity to accommodate different breathing preferences and sensitivity levels."
                ]
            },
            {
                name: "LED Visual Indicators",
                content: [
                    "Green LED for inhale and red LED for exhale, giving a clear, color-coded visual guide for each breathing phase.",
                    "LED lights turn off during hold phases, helping users stay on track with breath cycles and reinforcing the pauses."
                ]
            },
            {
                name: "Box Breathing Mode",
                content: [
                    "Pre-programmed Box Breathing cycle (4-4-4-4) with adjustable timings for each phase to support a calming, balanced breathing practice.",
                    "Easily switch to alternative breathing rhythms for various yoga practices or meditation preferences."
                ]
            },
            {
                name: "Wearable and Comfortable Design",
                content: [
                    "Integrated technology within a T-shirt or clothing item, allowing seamless, hands-free use during yoga or meditation.",
                    "Soft, breathable fabric that supports comfort and full range of motion."
                ]
            }
        ],
        hardwareComponents: {
            src: hardware,
            content: [
                {
                    name: "Metro Microcontroller",
                    text: "This microcontroller is the “brain” of the system, controlling the timing and activation of the vibration motor."
                },
                {
                    name: "Breadboard Wiring",
                    text: "The breadboard allows for easy connections between components without soldering. I used it to connect the vibration motor, resistors, and wires to the microcontroller."
                },
                {
                    name: "Vibrational Motor",
                    text: "The motor provides the vibrational feedback. When the microcontroller sends a signal, the motor vibrates at set intervals, simulating breathing cues (e.g., inhalation and exhalation) to guide yoga practitioners."
                }
            ]
        },
        interaction: {
            src: [interaction1, interaction2],
            content: [
                {
                    name: "Preparation",
                    content: [
                        {
                            name: "Wear the Clothing",
                            text: "The user puts on the yoga clothing embedded with vibrational motors and LED lights, ensuring a comfortable fit."
                        },
                        {
                            name: "Turn On the System",
                            text: "The user activates the clothing's vibrational and LED guidance system, either by an integrated switch or through an app, depending on the prototype's setup."
                        }
                    ]
                },
                {
                    name: "Initiating the Session",
                    content: [
                        {
                            name: "Select Breathing Mode",
                            text: "If the clothing has multiple breathing modes (e.g., Box Breathing, 4-7-8 Breathing), the user selects their preferred mode. For Box Breathing, the timing would follow a 4-4-4-4 pattern."
                        },
                        {
                            name: "Begin in a Starting Pose",
                            text: "The user gets into a relaxed starting position, such as seated or standing in Mountain Pose, ready to follow the breathing cues."
                        }
                    ]
                },
                {
                    name: "Guided Breathing Cycle",
                    content: [
                        {
                            name: "Inhale Phase (4 Seconds)",
                            text: "The clothing vibrates gently, signaling the user to inhale. Simultaneously, the green LED light turns on as a visual cue."
                        },
                        {
                            name: "Hold Phase after Inhale (4 Seconds)",
                            text: "The vibration and green LED turn off, signaling the user to hold their breath briefly."
                        },
                        {
                            name: "Exhale Phase (4 Seconds)",
                            text: "The vibration resumes, indicating the exhale phase, while the red LED light turns on."
                        },
                        {
                            name: "Hold Phase after Exhale (4 Seconds)",
                            text: "Both the vibration and red LED turn off, signaling the user to hold their breath once more."
                        }
                    ]
                },
                {
                    name: "Repeating the Cycle",
                    content: [
                        {
                            name: "Cycle Repetition",
                            text: "The breathing cycle (inhale-hold-exhale-hold) continues, with each phase timed by the vibrational and visual feedback. The user follows the rhythmic cues, maintaining focus on their breath and body alignment.\n"
                        }
                    ]
                },
                {
                    name: "Concluding the Session",
                    content: [
                        {
                            name: "End of Guided Breathing",
                            text: "After a set number of cycles or a user-defined duration, the vibrational and LED cues automatically stop, signaling the end of the guided breathing session."
                        },
                        {
                            name: "Power Off",
                            text: "The user turns off the system if it doesn't automatically shut down, readying it for the next session."
                        }
                    ]
                },
                {
                    name: "Post-Session Reflection",
                    content: [
                        {
                            name: "Reflection",
                            text: "The user can take a moment to reflect on their breathing practice, noting any relaxation, improved focus, or awareness achieved through the guidance of the vibrational cues.\n"
                        }
                    ]
                }
            ]
        },
        scaling: [
            {
                name: "Component Miniaturization",
                icon: <Miniature />,
                text: "Exploring options to miniaturize components (e.g., using smaller vibrational motors, compact microcontrollers) to ensure seamless integration into wearable fabrics without discomfort."
            },
            {
                name: "Battery Life and Power Management",
                icon: <Battery />,
                text: "Evaluating battery options for prolonged usage and considering low-power designs to ensure the motor and lighting components can last through typical yoga sessions without frequent recharging."
            },
            {
                name: "Material Compatibility",
                icon: <Material />,
                text: "Testing compatibility of electronics with flexible, breathable fabrics to maintain comfort and durability during movement. Ensuring that components are washable or easily removable for maintenance."
            },
            {
                name: "User Safety and Comfort",
                icon: <Safety />,
                text: "Ensuring that the vibrational intensity and temperature of components do not cause discomfort or harm to the user, especially during prolonged sessions."
            },
            {
                name: "Cost Analysis",
                icon: <Cost />,
                text: "Estimating the cost of scaling production, including sourcing of vibration motors, LEDs, and controllers, to assess market viability and potential pricing strategies."
            }
        ]
    }

]

export const ongoingProjects = [
    {
        name: "Volumetric Rendering",
        id: "volumetric-rendering",
        link: "/projects/volumetric-rendering",
        src: volumetric,
        description: "Volumetric rendering simulates the interaction of light with a medium, such as fog or smoke, by tracing rays through a 3D space and accumulating color and density values at each sample point along the ray. This process models absorption and scattering of light, producing realistic visual effects like glowing nebulae or hazy environments, with the final image being an accumulation of the radiance as it passes through the medium.",
        sections: [
            {
                title: "Volumetric Rendering and Fractal Noise for Cosmic Scene Creation",
                content: [
                    "A GLSL fragment shader that generates a dynamic cosmic scene using volumetric rendering and fractal noise was implemented. ",
                    "The shader creates an animated universe with a glowing central star, rotating nebulae, and clouds moving across the background. The starry sky is enhanced with fog and light effects, and the shader uses signed distance functions (SDF) to model objects like stars and nebulae.",
                    "The scene is dynamically animated with a combination of fractal Brownian motion (FBM) and 2D noise, which modulates the cloud and nebula behavior. The temperature-to-color mapping adds variation in the color of the nebulae and stars, and the twisting distortion effect is applied to the texture coordinates, further enhancing the cosmic feel.",
                    "The shader also incorporates a central star with a corona effect, alongside small background stars scattered across the scene."
                ],
                src: volumetric_rendering
            },
            {
                title: "Volumetric Ray Tracing with Revolving Objects",
                content: [
                    "The shader simulates a dynamic cosmic scene, where objects move in a circular orbit, mimicking the effects of revolution within a 3D space. This effect is achieved by continuously updating the positions of objects along a predefined circular path, with the sphere and volumetric data revolving around the central point. As the objects move, the shader computes the light interaction at every point along the ray's path, accumulating color and density based on the distance from the camera. The color values for each point are influenced by their position and their interaction with the surrounding space, with denser areas being more opaque and lighter areas becoming more transparent as the light travels through them.",
                    "Volumetric ray tracing is used to simulate realistic lighting and scattering effects within the scene. As light travels through the volume, the shader models how it interacts with different densities, resulting in effects such as fog, glow, and absorption. Each point sampled along the ray accumulates color and density from the objects it encounters, with the final color being a combination of light scattering through the material and direct light sources. The shader further enhances the scene by simulating the effects of light passing through a nebula-like structure, which dynamically evolves over time, creating a continuously shifting and immersive environment. This simulation of light scattering and material absorption adds depth and realism, allowing for the creation of complex, animated cosmic visuals."
                ],
                src: ray_tracing
            }
        ]
    },
    {
        name: "Neural SDF",
        id: "neural-sdf",
        link: "/projects/neural-sdf",
        src: cow_main,
        description: "Neural SDF (Signed Distance Function) rendering involves training a neural network to learn and predict the distance from any point in space to the nearest surface, enabling the creation of 3D objects. This approach leverages deep learning to efficiently generate complex shapes by encoding the distance information and utilizing ray marching to render the 3D models based on these learned SDF values.",
        sections: [
            {
                content: [
                    "I implemented Neural SDF Creation and Neural SDF Rendering to generate 3D models based on Signed Distance Functions (SDF) and ray marching. The dataset was prepared by converting 3D point data and their corresponding SDF values into PyTorch tensors, which are used to train a neural network. The network architecture consists of fully connected layers with sine activations for improved gradient flow, leveraging residual connections. The training process involved calculating the mean squared error (MSE) loss and updating model weights using backpropagation. Key hyperparameters like hidden layer width, number of iterations, and learning rate were tuned to optimize performance.",
                    "The Neural SDF Rendering process computes the SDF for 3D objects, such as a bunny and cow, by performing ray marching and sphere tracing. The ray marching technique evaluates the distance from each point along the ray to the nearest surface using the SDF. The loop iterates until a surface is hit, and the normal vector is calculated to enable correct shading and lighting. The Phong shading model was applied, with colors assigned based on object location in the scene, such as red for the bunny and green for the cow. Finally, the models were rendered using these techniques, producing detailed and realistic 3D shapes, including a seahorse and teddy bear model, with different training parameters for each object."
                ],
                src: [
                    rabbit, cow, seahorse, teddy
                ],
                video: neural_sdf
            },
        ]
    },
    {
        name: "SDF Primitives and Representation",
        id: "sdf-primitives-and-representation",
        link: "/projects/sdf-primitives-and-representation",
        src: starry_night,
        description: "Signed distance functions for spheres, planes, and boxes were implemented by calculating the shortest distance from a point to each shape, enabling efficient ray-based traversal.",
        technicalExplanation: {
            content: [
                {
                    title: "SDF Primitives",
                    text: "Signed distance functions for spheres, planes, and boxes were implemented by calculating the shortest distance from a point to each shape, enabling efficient ray-based traversal."
                },
                {
                    title: "Boolean Operations",
                    text: "Union, intersection, and subtraction functions were implemented to combine the primitives into more complex objects using constructive solid geometry."
                },
                {
                    title: "Scene Composition",
                    text: "The boolean operations were used to construct the final scene, carefully positioning objects and applying subtraction to create cut-out effects."
                },
                {
                    title: "Ray Marching",
                    text: "An algorithm that steps along a ray, accumulating distances until it hits an object or exits the scene was written."
                },
                {
                    title: "Normal Calculation",
                    text: "With the use of finite differences, surface normals were estimated by sampling the SDF at nearby points, which helped simulate realistic lighting."
                },
                {
                    title: "Shading and Coloring",
                    text: "The Phong shading model was used, casting secondary rays to compute shadows and assigning unique colors to each object to ensure clarity while maintaining realistic illumination and occlusion effects."
                }
            ],
            src: technical_explanation
        },
        creativeExpression: {
            content: [
                "Van Gogh's Starry Night was reimagined in a blobby, surreal style using SDF primitives.",
                "Moon - Subtracting one sphere from another to form a crescent.",
                "Trees - SDF deformation using a wave function for a crooked and organic look.",
                "Stars - Manually positioned using a combination of SDF spheres and procedural placement, with varying sizes to mimic the swirling effect of the original painting.",
                "Buildings - Constructive Solid Geometry (CSG) by combining multiple spheres to achieve a clustered, soft aesthetic.",
                "The objects where blended using smooth min, allowing a smooth transition between different SDEs, for a fluid look. Ray marching was used initially but later switched to sphere marching, thereby improving the performance by taking larger steps in empty space.",
                "Phong shading in ray marching was used to create depth and lighting effects, while the clouds were animated with procedural motion, making them swirl and float dynamically.",
                "Textural sampling helped incorporate procedural randomness for variations in buildings and light placements."
            ],
            src: sdf
        }
    },
    {
        name: "Clothing-Enhanced Human Activity Recognition Using Synthetic IMU Data and Deep Learning Models",
        id: "clothing-enhanced-har",
        link: "/projects/clothing-enhanced-har",
        src: clothing_har,
        description: "This project aims to enhance Human Activity Recognition (HAR) with sensors placed on loosely-worn clothing using synthetic Inertial Measurement Unit (IMU) data generation. Deep learning models are trained on this data to improve the accuracy and robustness of activity recognition systems.",
        technicalApproach: [
            "This project seeks to bridge the gap between synthetic and real-world data by integrating clothing simulation pipelines with classical machine learning and deep learning models for Human Activity recognition (HAR). HAR, today, relies on sensors placed directly on the human body. This is not a feasible approach as it is uncomfortable for long-term use. The alternative to this is by placing sensors on the clothing worn by humans. By leveraging synthetic data generation techniques, a dataset that mimics real-world scenarios while being non-intrusive and easily scalable can be created.",
            "To create such simulations, the CAPE dataset is used as a base. The CAPE dataset is a large-scale dataset of human motion capture data in various clothing types. This data is used to train a deep learning model to predict the motion of the human body based on the clothing worn. The synthetic IMU data is then generated based on this predicted motion and the variation with clothing is captured. This synthetic data is then used to train a deep learning model for HAR.",
        ],
        clothedData: {
            content: [
                "The clothed motions from the CAPE dataset are provided in the form of SMPL poses for each frame in the sequence. The pose information is then converted into a mesh object using the SMPL model. All the frames are then combined into an animated video of the motion. This video is then passed into IMUTube to retrieve the synthetic IMU data for the clothed motion."
            ],
            src: clothed
        },
        unclothedData: {
            content: [
                "While the unclothed motion information is not provided in the CAPE dataset, the unclothed T-Pose for each model has been provided along with the clothed T-Pose, such that the clothing mimics the motion of the body in T-Pose. Using these two, an approximate beta value is determined for the clothing and subtracted from the clothed motion to estimate the unclothed motion.",
                "The clothed motion process is repeated for the unclothed motion, and the synthetic IMU data is generated for the unclothed motion."
            ],
            src: unclothed
        }
    },
]
import React from 'react';
import {volunteering} from "../content/volunteering";

const Volunteering = () => {
    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                Outside of tech, I{" "}
                                <span className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    Volunteer
                                </span>!
                            </h1>
                            <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
                                I am devoted to taking up responsibility when performing community service of different
                                kinds. Be it for organising events, peer-to-peer learning or giving back to the society.
                            </p>
                        </header>
                        <div className="mt-16 sm:mt-20">
                            <ul role="list" className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
                                {volunteering.map((x, i) => {
                                    return (
                                        <li key={i} className="group relative flex flex-col items-start">
                                            <div className={`relative aspect-[9/10] w-44 flex-none overflow-hidden rounded-xl bg-zinc-100 dark:bg-zinc-800 sm:w-72 sm:rounded-2xl ${i % 2 === 0 ? "rotate-2" : "-rotate-2"}`}>
                                                <img alt="" loading="lazy" decoding="async" data-nimg="1"
                                                     className="absolute inset-0 h-full w-full object-cover"
                                                     style={{color: "transparent"}}
                                                     src={x.src}/>
                                            </div>
                                            <p className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                                                {x.description}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Volunteering;